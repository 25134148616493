<template src="./form.html"></template>

<script>
import Http from '@/shared/http-config'
import Editor from '@tinymce/tinymce-vue';
import tinymce from '@/shared/tinymce'
import ImageRotate from '@/shared/ImageRotate'
import moment from 'moment'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import EmojiPicker from '@/shared/EmojiPicker'
import introJs from '@/shared/intro.js';

export default {
  name: "SocialPostForm",
  components: {'tinymceEditor': Editor, DatePicker, ImageRotate, EmojiPicker},
  mixins: [tinymce],
  props: {
    linkable: {
      type: Object,
      default: null
    },
    copyPosts: {
      type: Array,
      default: null
    },
  },
  data: function () {
    return {
      user: {},
      activeTab: null,
      postTypes: [
        {
          id: 0,
          active: 1,
          type: 'fb',
          picture: null,
          new_picture: false,
          text: '',
          link: '',
          date: typeof(this.$route.params.date) !== "undefined" && this.$route.params.date !== null ? this.$route.params.date : null,
          time: '10:00:00',
        },
        {
          id: 0,
          active: 1,
          type: 'insta',
          picture: null,
          new_picture: false,
          text: '',
          link: '',
          date: typeof(this.$route.params.date) !== "undefined" && this.$route.params.date !== null ? this.$route.params.date : null,
          time: '10:00:00',
        },
      ],
      categories: [
        { value: 9, text: 'Redaktionsplan' },
        { value: 2, text: 'Bestanden' },
      ],
      category_id: 9,
      typeTitle: {
        fb: 'Facebook',
        insta: 'Instagram'
      },
      posts: [],
      templates: [],
      postsToDelete: [],
      postTime: [...new Array(23)].map((x, y)=> { return (y < 9 ? '0' : '') + (y + 1) + ':00:00' }),
    }
  },
  watch: {
    category_id: function (value) {
      if(value > 0 && value !== 9){
        this.loadTemplates();
      }
    },
    posts: function (newValue, oldValue) {
      let self = this;
      let oldIds = oldValue.map(item => item.id);
      let newIds = newValue.map(item => item.id);
      let intersection = oldIds.filter(item => !newIds.includes(item));
      if(intersection.length > 0){
        let post = oldValue.filter(item => item.id === intersection[0]).shift();
        self.postsToDelete.push(post.id);
        self.postsToDelete = [...new Set(self.postsToDelete)]; // unique post_id
      }
      self.posts.forEach(post => {
        if(post.date === null && self.linkable && self.linkable.data && self.linkable.data.date && self.linkable.data.date.start){
          post.date = moment(self.linkable.data.date.start).format('YYYY-MM-DD');
        }
        else if(post.date === null && self.linkable && self.linkable.data && self.linkable.data.date){
          post.date = moment(self.linkable.data.date).format('YYYY-MM-DD');
        }
      });
    }
  },
  mounted() {
    introJs.firstLoad('social-post-details');
  },
  created() {
    let self = this;
    self.user = self.$store.getters.getUser;

    if(self.user.user_group === 1){
      self.postTypes.map(post => { post.active = 0; post.link = 'https://#userUrl#' });
    }

    introJs.setOption('steps', [
      {
        element: '#social-post-details h1',
        intro: "Auf dieser Seite kannst du die Inhalte deines neuen Posts anlegen.",
      },
      {
        element: '#social-post-details #post-types',
        intro: "Hier wählst du die Kanäle aus, für die du einen Post anlegen möchtest. Hake sie jetzt an.",
      },
      {
        element: '#social-post-details .nav-tabs',
        intro: "Über diese Tabs kannst du zwischen der Facebook- und Instagram-Version deines Posts hin- und herwechseln. Klicke jetzt auf den zweiten Tab.",
      },
      {
        element: '#social-post-details .tab-content',
        intro: "Hier kannst du neben dem Datum und der Uhrzeit auch die Inhalte deines Posts hinterlegen. Über Textvorlagen, kannst du vorgefertigte Texte auswählen, um deine Arbeit zu beschleunigen. Achtung! Solltest du bereits einen eigenen Text eingegeben haben und dann eine Vorlage auswählen, wird dein Text überschrieben. Nachdem du ein Bild zu deinem Post hinzugefügt hast, sind deine Vorbereitungen fast abgeschlossen.",
      },
      {
        element: '#social-post-details #copy-post-data',
        intro: "Mit Klick auf die Kopierfunktion sparst du dir ein wenig Arbeit und kannst den Text aus dem vorherigen Tab übernehmen. Ein Bild muss jedoch im Anschluss manuell ergänzt werden.",
      },
      {
        element: '#social-post-details .primary-action',
        intro: "Vergiss nicht, deine Änderungen zum Schluss zu speichern. Anschließend wird dein Post in deinem Redaktionsplan angezeigt.",
      },
    ]);

    introJs.onbeforechange(function(targetElement) {
      // check if new steps are availible
      introJs._options.steps.forEach(function (step, key) {
        if(step.element){
          introJs._introItems[key].element = document.querySelector(step.element);
          introJs._introItems[key].position = step.position ? step.position : 'bottom';
        }
      });

      if(introJs._currentStep === 2 && self.posts && self.posts.length !== 2){
        return false;
      }
      if(introJs._currentStep === 3 && self.activeTab === 0){
        return false;
      }
    });


    if(self.linkable !== null){
      if(self.linkable.data && self.linkable.data.posts && self.linkable.data.posts.length > 0){
        self.posts = self.linkable.data.posts;
        // set associated postTypes for checkbox selection
        self.linkable.data.posts.forEach(function (post){
          self.category_id = post.category.id;
          post.active = post.active ? 1: 0;
          post.time = moment(post.date).format('HH:mm:ss');
          post.date = moment(post.date).format('YYYY-MM-DD');
          self.$set(self.postTypes, self.postTypes.findIndex(item => item.type === post.type), post);
        });
      }
      else {
        if(self.linkable.type === 'course'){
          self.category_id = 3;
        }
        else if(self.linkable.type === 'guestbook'){
          self.category_id = 4;
        }
        else if(self.linkable.type === 'news'){
          self.category_id = 5;
        }
      }
    }

    if(self.copyPosts !== null && self.copyPosts.length > 0){
      // set associated postTypes for checkbox selection
      self.posts = self.copyPosts;
      self.copyPosts.forEach(function (post){
        post.id = 0;
        post.active = post.active ? 1: 0;
        post.time = moment(post.date).format('HH:mm:ss');
        post.date = moment(post.date).format('YYYY-MM-DD');
        self.category_id = post.category.id;

        fetch(post.picture)
          .then(function(response) {
            return response.blob();
          })
          .then(function(blob) {
            post.new_picture = new File([blob], "picture.jpg", {type: "image/jpeg"});
            self.$set(self.postTypes, self.postTypes.findIndex(item => item.type === post.type), post);
          });
      });
    }
    else if(self.linkable && self.linkable.data.posts && self.linkable.data.posts.length === 0){
      // insert default image
      let images = {
        3: [
          'https://static.fahrschul-system.de/images/social/course_1.jpg',
          'https://static.fahrschul-system.de/images/social/course_2.jpg',
          'https://static.fahrschul-system.de/images/social/course_3.jpg'
        ],
        4: [
          'https://static.fahrschul-system.de/images/social/guestbook_1.jpg',
          'https://static.fahrschul-system.de/images/social/guestbook_2.jpg',
          'https://static.fahrschul-system.de/images/social/guestbook_3.jpg'
        ],
        5: [
          'https://static.fahrschul-system.de/images/social/news_1.jpg',
          'https://static.fahrschul-system.de/images/social/news_2.jpg',
          'https://static.fahrschul-system.de/images/social/news_3.jpg'
        ]
      };
      if(images[self.category_id]){
        let image = images[self.category_id][Math.floor(Math.random() * images[self.category_id].length)];
        fetch(image)
          .then(function(response) {
            return response.blob();
          })
          .then(function(blob) {
            self.postTypes.forEach(function (post){
              post.picture = image;
              post.new_picture = new File([blob], "picture.jpg", {type: "image/jpeg"});
            });
          });
      }
    }
  },
  methods: {
    onFileChange(e, post) {
      let self = this;
      post.new_picture = e.target.files[0];
      let imgPreviewUrl = URL.createObjectURL(post.new_picture);

      let img = new Image();
      img.src = imgPreviewUrl;
      img.onload = function() {
        if (img.naturalWidth >= 500 && img.naturalHeight >= 500) {
          post.picture = imgPreviewUrl;
        }
        else {
          post.picture = null;
          self.$bvToast.toast('Das Bild muss min. 500px breit und 500px hoch sein.', {
            title: 'Bild für ' + self.typeTitle[post.type] + ' zu klein',
            variant: 'danger',
            autoHideDelay: 10000
          })
        }
      };
    },
    deletePic (post){
      post.new_picture = false;
      post.picture = null;
    },
    loadTemplates(){
      let self = this;
      Http.request('GET', '/social/categories/' + self.category_id + '/templates').then((templates) => {
        self.templates = [];
        templates.data.forEach(function (template) {
          self.templates.push({
            text: template.text,
            value: template
          });
        });
      });
    },
    store(linkable = false){
      let self = this;

      return new Promise((resolve, reject) => {
        self.validate().then(function (result){
          if(result && self.posts.length > 0) {
            // delete deselected posts, prevent delete if reselected
            self.postsToDelete.filter(post_id => !self.posts.map(post => post.id).includes(post_id)).forEach(function (post_id){
              Http.request('DELETE', '/social/posts/' + post_id);
            });

            self.getPostLinkable(linkable).then(function (linkable){
              self.posts.forEach(function (item, index) {

                let formData = new FormData();
                formData.append(linkable.type + '[id]', linkable.data.id);
                formData.append('active', item.active);
                formData.append('type', item.type);
                formData.append('text', item.text);
                formData.append('link', item.link);
                formData.append('date', item.date + ' ' + item.time);
                formData.append('category[id]', self.category_id);

                if(typeof(self.$refs['imageRotate-' + item.type]) !== "undefined"){
                  // check if img was rotated
                  self.$refs['imageRotate-' + item.type][0].getImage(true).then(function (imageRotateResponse){
                    if(imageRotateResponse){
                      item.new_picture = imageRotateResponse;
                    }
                    if(typeof item.new_picture !== "undefined" && item.new_picture !== false) {
                      formData.append('new_picture', item.new_picture);
                    }
                    Http.request('POST', '/social/posts/' + (item.id > 0 ? item.id : ''), formData).then(function (){
                      if(self.posts.length === (index + 1)){
                        resolve();
                      }
                    });
                  });
                }
                else {
                  Http.request('POST', '/social/posts/' + (item.id > 0 ? item.id : ''), formData).then(function (){
                    if(self.posts.length === (index + 1)){
                      resolve();
                    }
                  });
                }
              });
            });
          }
          else if(self.posts.length === 0){
            resolve();
          }
        });
      });
    },
    validate(){
      let self = this;
      return self.$refs.postFormObserver.validate().then(function (valid){
        if(!valid || self.posts.filter(item => item.text === '').length > 0){
          self.$bvToast.toast('Bitte fülle alle Post Pflichtfelder aus.', {
            title: 'Pflichtfelder',
            variant: 'danger',
            autoHideDelay: 5000
          })
          return false;
        }
        else if(self.posts.filter(item => item.id === 0 && item.type === 'insta' && !item.new_picture).length > 0){
          self.$bvToast.toast('Bitte laden Sie ein Bild für Instagram hoch.', {
            title: 'Pflichtfelder',
            variant: 'danger',
            autoHideDelay: 5000
          })
          return false;
        }
        else if (self.posts.length === 0 && self.$route.name === 'social_post_details'){
          self.$bvToast.toast('Bitte wähle ein Kanal aus.', {
            title: 'Pflichtfelder',
            variant: 'danger',
            autoHideDelay: 5000
          })
          return false;
        }
        else if (self.posts.filter(item => moment(item.date + ' ' + item.time).isBefore()).length > 0){
          self.$bvToast.toast('Das Datum liegt in der Vergangenheit.', {
            title: 'Datum korrigieren',
            variant: 'danger',
            autoHideDelay: 5000
          })
          return false;
        }

        return true;
      });
    },
    getPostLinkable(linkable){
      let self = this;
      return new Promise((resolve) => {
        if(linkable !== false && linkable.data.id > 0){
          return resolve(linkable);
        }
        else if (self.linkable && self.linkable.data && self.linkable.data.id > 0) {
          return resolve(self.linkable);
        }
        else {
          Http.request('POST', '/social/meta').then(function (meta) {
            return resolve({type: 'meta', data: meta.data});
          });
        }
      });
    },
    copyPostData(post){
      post.text = this.posts[0].text;
      post.link = this.posts[0].link;
      post.date = this.posts[0].date;
      post.time = this.posts[0].time;
    },
    setTemplate(template, post){
      let self = this;
      if(post.text === ''){
        post.text = self.replaceLinkableData(template.text);
        template.link ? post.link = template.link : null;
      }
      else {
        self.$bvModal.msgBoxConfirm('Du hast bereits einen Text für deinen Post eingegeben. Mit Auswahl der Vorlage wird dieser überschrieben und unwiderruflich gelöscht. Soll die Textvorlage wirklich verwendet werden?', {
            headerBgVariant: 'primary',
            title: 'Textvorlage wirklich verwenden?',
            okTitle: 'Ja, Vorlage verwenden',
            cancelTitle: 'Nein, Text behalten',
            cancelVariant: 'outline-danger'
          })
          .then(value => {
            if(value === true){
              post.text = self.replaceLinkableData(template.text);
              template.link ? post.link = template.link : null;
            }
            else {
              post.template_id = undefined;
            }
          });
      }
    },
    replaceLinkableData(text){
      let self = this;
      if(self.linkable.type === 'course'){
        text = text.replaceAll('{thema}', self.linkable.data.name);
        text = text.replaceAll('{datum}', moment(self.linkable.data.date.start).format('DD.MM.'));
        text = text.replaceAll('{ort}', self.linkable.data.city);
      }
      else if(self.linkable.type === 'news'){
        text = text.replaceAll('{title}', self.linkable.data.title);
      }
      else if(self.linkable.type === 'guestbook'){
        text = text.replaceAll('{name}', self.linkable.data.name);
        text = text.replaceAll('{title}', self.linkable.data.title);
        text = text.replaceAll('{text}', self.linkable.data.text);
        text = text.replaceAll('{ort}', self.linkable.data.city);
      }
      return text;
    }
  }
}
</script>

<style scoped></style>
